<template>
  <UContainer>
    <UCard class="my-10">
      <template #header>
        Ellipsis Rope Events
      </template>

    <slot />
  </UCard>
    
  </UContainer>
</template>